
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

interface Filter {
  name: string;
  from_date: string;
  to_date: string;
  is_active: number | string;
}

export default defineComponent({
  name: "price-rules-dropdown",
  props: {
    // optionsParentId: { type: Array, required: true },
  },
  components: {},
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const { t } = useI18n();
    
    const formRef = ref<null | HTMLFormElement>(null);
    const data = ref<Filter>({
      name: "", //非必须
      from_date: "", // 非必须
      to_date: "", //非必须
      is_active: "", //非必须 no:0,yes:1
    });

    const submit = () => {
      emit("submit-filter", data.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    return {
      data,
      formRef,
      submit,
      handleReset,
    };
  },
});
