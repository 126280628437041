
import { defineComponent, ref, onMounted, computed, onBeforeMount } from "vue";
import { MBDatatable } from "magicbean-saas-common";
import addDistributionPriceRulessModal from "@/views/distribution-network/distribution-price-rules/addDistributionPriceRulessModal.vue";
import FilterDropdwon from "@/views/distribution-network/distribution-price-rules/FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiPriceRules } from "@/core/api";
import moment from "moment";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import { formatDate, setModuleBCN } from "@/core/directive/function/common";
import { useRoute } from "vue-router";

interface PriceRules {
  id: number;
  name: string;
  description: string;
  from_date: string;
  to_date: string;
  is_active: number;
  discard_subsequent_rules: number;
  conditions_serialized: string;
  sorting: number;
  action: number;
  value: string;
}

interface SortOption {
  field: string;
  direction: string;
}

interface FilterOption {
  field: string;
  value?: string;
  from?: string;
  to?: number;
  condition?: string;
}

export default defineComponent({
  name: "PriceRules",
  components: {
    MBDatatable,
    FilterDropdwon,
    addDistributionPriceRulessModal,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const loading = ref(true);
    const tableData = ref<Array<PriceRules>>([]);
    const checkedDistributionPriceRules = ref([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref<string>("");
    const sortOptions = ref<Array<SortOption>>([
      { field: "id", direction: "desc" },
    ]);
    const filterOptions = ref<Array<FilterOption>>([]);
    const tableHeader = ref([
      {
        name: "Price Rules ID",
        key: "id",
        sortable: true,
      },
      {
        name: "Name",
        key: "name",
      },

      {
        name: "Description",
        key: "description",
      },
      {
        name: "From Date",
        key: "from_date",
        sortable: true,
      },
      {
        name: "To Date",
        key: "to_date",
        sortable: true,
      },
      {
        name: "Is Active",
        key: "is_active",
      },
      {
        name: "Discard Subsequent Rules",
        key: "discard_subsequent_rules",
      },
      {
        name: "Conditions Serialized",
        key: "conditions_serialized",
      },
      {
        name: "Sorting",
        key: "sorting",
        sortable: true,
      },
      {
        name: "Action",
        key: "action",
      },
      {
        name: "Value",
        key: "value",
      },
      {
        name: "", // t("common.actions")
        key: "actions",
      },
    ]);

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route);
      getDistributionPriceRulesList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    });

    const getDistributionPriceRulesList = (
      page: number,
      pageSize: number,
      search?: string,
      sortOptions?: Array<SortOption>,
      filterOptions?: Array<FilterOption>
    ) => {
      loading.value = true;
      ApiPriceRules.getPriceRulesList({
        page: page,
        page_size: pageSize,
        search_value: search,
        sort_orders: sortOptions,
        filter_group: filterOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] != "") {
          if (item == "from_date") {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "gteq",
            });
          } else if (item == "to_date") {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "lteq",
            });
          } else if (item == "name") {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "like",
            });
          } else {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "eq",
            });
          }
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      search.value = "";
      currentPage.value = 1;
      getDistributionPriceRulesList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const handleFilterReset = () => {
      resetFilter();
      getDistributionPriceRulesList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getDistributionPriceRulesList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      getDistributionPriceRulesList(
        page,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize) => {
      pageSize.value = pagesize;
      getDistributionPriceRulesList(
        currentPage.value,
        pagesize,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const debounceSearch = _.debounce(getDistributionPriceRulesList, 1000);

    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const removeSearch = () => {
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    const updateList = () => {
      getDistributionPriceRulesList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    return {
      t,
      formatDate,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      checkedDistributionPriceRules,
      getDistributionPriceRulesList,
      onCurrentPageChange,
      onRowsPerPageChange,
      searchItems,
      removeSearch,
      onColumnSort,
      handleFilter,
      handleFilterReset,
      updateList,
    };
  },
});
